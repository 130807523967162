import React, { useEffect, useState } from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";
import EditTimeSlotsComp from "../../../../../../Components/EditTimeSlots";
import {
  getAllTimeSlotsByDateNIdApi,
  removeTimeSlotByIdApi,
  updateTimeSlotsApi,
} from "../../Constants/api";
import SweetAlert from "react-bootstrap-sweetalert";

const EditTimeSlot = () => {
  const [advisorData, setAdvisorData] = useState();
  const [allTime, setAllTime] = useState({});
  const [timeSlotById, setTimeSlotById] = useState("");
  const [showAlert, setShowAlert] = useState(false);
  const [timeAdvisorData, setTimeAdvisorData] = useState([]);
  const [deleteSlot, setDeleteSlot] = useState("");
  const [allIsBooked, setAllIsBooked] = useState({});
  const [selectedDate, setSelectedDate] = useState("");
  const [timeAdvisorBack, setTimeAdvisorBack] = useState("");
  const [showUnPublishAlert, setShowUnPublishAlert] = useState(false);
  const [showUnPublishConfirm, setShowUnPublishConfirm] = useState(false);
  const [formData, setFormData] = useState({
    supportAdvisorId: "",
    date: "",
    isBooked: "",
    time: "",
  });

  const history = useHistory();
  const { editID } = useParams();
  const { supportAdvisorId, date, isBooked, time } = formData;
  const queryParameters = new URLSearchParams(window.location.search);
  const dateParam = queryParameters.get("date");
  const advisorId = queryParameters.get("advisorId");

  const convertUTCtoIST = (utcDateStr) => {
    const utcDate = new Date(utcDateStr);
    const istOffset = 5.5 * 60 * 60 * 1000;
    const istDate = new Date(utcDate.getTime() + istOffset);
    return istDate.toISOString().slice(0, -1);
  };

  const getAllTimeSlotsByAdvsiorIdData = async () => {
    const { data } = await getAllTimeSlotsByDateNIdApi({
      supportAdvisorId: advisorId,
      date: dateParam,
    });
    setDeleteSlot(data.data);
    setTimeAdvisorData(
      data?.data?.slots?.map((ele) => {
        return {
          time: convertUTCtoIST(ele?.time),
          isBooked: ele?.isBooked,
          _id: ele?._id,
        };
      })
    );
  };

  useEffect(() => {
    getAllTimeSlotsByAdvsiorIdData();
  }, []);

  const addTimeNFree = () => {
    try {
      let be = new Date(dateParam);
      let nnn =
        be.getUTCMonth() + 1 <= 9
          ? `0${be.getUTCMonth() + 1}`
          : be.getUTCMonth() + 1;
      let formattedDay =
        be.getUTCDate() <= 9 ? `0${be.getUTCDate()}` : be.getUTCDate(); // Adjusted formattedDay

      let a = `${be.getUTCFullYear()}-${nnn}-${formattedDay}T${allTime?.value}:00:00.000`;

      if (allTime?.value !== "") {
        // Check if the time already exists in timeAdvisorData
        if (timeAdvisorData.some(slot => slot.time === a)) {
          console.log("Time already exists");
          return; // Exit function if time already exists
        }

        const newTimeSlot = {
          time: a, // Store complete ISO datetime string
          isBooked: allIsBooked?.value, // Use allIsBooked for isBooked value
        };

        setTimeAdvisorData([
          ...timeAdvisorData,
          newTimeSlot,
        ]);

        // Optionally update setTimeAdvisorBack
        setTimeAdvisorBack([
          ...timeAdvisorBack,
          newTimeSlot,
        ]);

        // Clear input fields or reset state as needed
        setAllTime({});
        setAllIsBooked({});
      }
    } catch (error) {
      console.log(error);
    }
  };



  console.log(timeAdvisorData, 'timeadvisordata')

  const removeTimeNFree = async (indexToRemove) => {
    const updatedTimeNFree = [...timeAdvisorData];

    const newTime = updatedTimeNFree?.filter(
      (idx, index) => index !== indexToRemove
    );
    setTimeAdvisorData(newTime);

    try {
      console.log(
        deleteSlot.supportAdvisorId,
        {
          date: deleteSlot.date,
          slots: { time: getFormattedTime(indexToRemove?.time) },
        },
      );
      await removeTimeSlotByIdApi(deleteSlot.supportAdvisorId, {
        date: deleteSlot.date,
        slots: { time: getFormattedTime(indexToRemove?.time) },
      });
    } catch (error) {
      console.log(error);
    }
  };

  const onConfirm = () => {
    setShowAlert(false);
    window.location.reload();
  };

  const onCancel = () => {
    setShowAlert(false);
  };

  const getFormattedTime = (time) => {

    let localDate = new Date(time);
    let utcDate = localDate;
    let year = utcDate.getUTCFullYear();
    let month = String(utcDate.getUTCMonth() + 1).padStart(2, '0'); // Months are 0-based
    let day = String(utcDate.getUTCDate()).padStart(2, '0');
    let hours = String(utcDate.getUTCHours()).padStart(2, '0');
    let minutes = String(utcDate.getUTCMinutes()).padStart(2, '0');
    let seconds = String(utcDate.getUTCSeconds()).padStart(2, '0');
    let milliseconds = String(utcDate.getUTCMilliseconds()).padStart(3, '0');
    let utcFormatted = `${year}-${month}-${day}T${hours}:${minutes}:${seconds}.${milliseconds}`;

    return utcFormatted
  }

  const getUTCFormat = (timeArray) => {
    let timeList = [];
    timeArray.forEach(ele => {
      let localDate = new Date(ele?.time);
      let utcDate = localDate;
      let year = utcDate.getUTCFullYear();
      let month = String(utcDate.getUTCMonth() + 1).padStart(2, '0'); // Months are 0-based
      let day = String(utcDate.getUTCDate()).padStart(2, '0');
      let hours = String(utcDate.getUTCHours()).padStart(2, '0');
      let minutes = String(utcDate.getUTCMinutes()).padStart(2, '0');
      let seconds = String(utcDate.getUTCSeconds()).padStart(2, '0');
      let milliseconds = String(utcDate.getUTCMilliseconds()).padStart(3, '0');
      let utcFormatted = `${year}-${month}-${day}T${hours}:${minutes}:${seconds}.${milliseconds}`;
      timeList.push({ time: utcFormatted })
    })

    return timeList
  }
  const handleSubmitTimeSlots = async () => {
    try {
      setShowUnPublishAlert(true);
      await updateTimeSlotsApi(editID, {
        slots: getUTCFormat(timeAdvisorBack)
      });
      setShowUnPublishAlert(false);
      setShowUnPublishConfirm(true);
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <>
      <EditTimeSlotsComp
        advisorData={advisorData}
        setAdvisorData={setAdvisorData}
        formData={formData}
        setFormData={setFormData}
        supportAdvisorId={supportAdvisorId}
        date={date}
        isBooked={isBooked}
        allTime={allTime}
        setAllTime={setAllTime}
        allIsBooked={allIsBooked}
        onConfirm={onConfirm}
        onCancel={onCancel}
        setShowAlert={setShowAlert}
        setAllIsBooked={setAllIsBooked}
        selectedDate={selectedDate}
        setSelectedDate={setSelectedDate}
        time={time}
        addTimeNFree={addTimeNFree}
        timeAdvisorData={timeAdvisorData}
        removeTimeNFree={removeTimeNFree}
        setTimeSlotById={setTimeSlotById}
        timeSlotById={timeSlotById}
        handleSubmitTimeSlots={handleSubmitTimeSlots}
        setShowUnPublishAlert={setShowUnPublishAlert}
      />

      {showUnPublishAlert ? (
        <SweetAlert
          title="Are you sure?"
          warning
          showCancel
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="danger"
          confirmBtnText="Yes,Edit it!"
          reverseButtons={true}
          onConfirm={() => {
            handleSubmitTimeSlots();
            setShowUnPublishAlert(false);
          }}
          onCancel={() => setShowUnPublishAlert(false)}
        ></SweetAlert>
      ) : null}

      {showUnPublishConfirm ? (
        <SweetAlert
          success
          title="Edited!"
          confirmBtnBsStyle="primary"
          onConfirm={() => {
            setShowUnPublishAlert();
            setShowUnPublishConfirm();
            window.location.reload();
          }}
        >
          Time Slots for Advisor has been Edited
        </SweetAlert>
      ) : null}

      {showAlert && (
        <SweetAlert
          success
          title="Deleted successfully!"
          onConfirm={onConfirm}
        // onCancel={onCancel}
        >
          You clicked the button!
        </SweetAlert>
      )}
    </>
  );
};

export default EditTimeSlot;
